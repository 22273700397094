import React from 'react'
import Navbar from '../inc/Navbar'
import Slider from '../inc/Slider'
import ScrollToTop from "react-scroll-to-top";
import Drawingcerificate from "../images/Drawing Certificate.jpeg"
import ReactPlayer from "react-player";

import "./Home.css";
import Designation from './Designation';
import puja2024 from "../images/Krishnagar Prabhat Sangha.jpeg"
import Dancecertificate from "../images/Dance Certificate.jpeg"

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import Award from './Award';
import SocialActivity from './SocialActivity';


function Home() {
    return (
        <div>
            <Navbar />

            <br />
            <br />
            <br/>
            <br/>
            <br />
            <ScrollToTop smooth top="100" color="maroon"/>
            <div className='album py-0'>
                <div className='container'>
                    <Slider />
                </div>
            </div>
            <br />
            <div className="album py-3">
        <div className="container">
          <div className="row">
            <div className="videreactjs">
              <ReactPlayer width="100%" height="100%" url="https://www.youtube.com/watch?v=H95glLI_x7c"/>
            </div>
          </div>
        </div>
      </div>
   
            <div className='album py-1'>
                <div className='container'>
                    <div className='row'>

                        <div className="col-md-4 mt-3">
                            
                                <div className='btn-grad33'>Krishnagar Prabhat Sangha</div>
                            <Zoom>
                                <img src={puja2024} className="card-img-top" alt="Krishnagar Prabhat Sangha"/>
                                </Zoom>
                            
                        </div>
                        <div className="col-md-4 mt-3">
                        <div className='btn-grad33'>DRWAING CERTIFICATE</div>
                            <Zoom>
                                <img src={Drawingcerificate} className="card-img-top" alt="Drawingcerificate"/>
                                </Zoom>
                            
                        </div>
                        <div className="col-md-4 mt-3">
                        <div className='btn-grad33'>DANCE CERTIFICATE</div>
                            <Zoom>
                                <img src={Dancecertificate} className="card-img-top" alt="Dancecertificate"/>
                                </Zoom>
                           
                        </div>


                    </div>
                </div>
            </div>
            <br/>
            <div className='album py-1'>
                <div className='container'>

                    <center>
                        <h2 style={{ color: "darkBlue" }}><b style={{ color: "maroon" }}>PREVIOUS </b>YEAR PUJA</h2>
                    </center>

                </div>
            </div>
            <div className='album py-0'>
                <div className='container'>
                    <div className='row'>
                        <div className="col-md-4 mt-3">
                            <div className="card">
                                <iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2Fprabhat.sangha.56%2Fvideos%2F791862329189623%2F&show_text=true&width=261&t=0" height="380" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="card">
                                <iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2Fprabhat.sangha.56%2Fvideos%2F3769332636662826%2F&show_text=true&width=267&t=0" height="380" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="card">
                                <iframe src="https://www.facebook.com/plugins/video.php?height=317&href=https%3A%2F%2Fwww.facebook.com%2Fprabhat.sangha.56%2Fvideos%2F1207834303155511%2F&show_text=true&width=560&t=0" height="380" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="card">
                            <iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2Fprabhat.sangha.56%2Fvideos%2F935461657407181%2F&show_text=true&width=261&t=0" height="380"  scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                            </div>
                        </div>
                      
                    </div>
                </div>
            </div>
            <br />
            <br />
            <Designation />
            <br />
            <div className="maajagadhatri">
                <center>
                    <div className='fontsize'>
                        <h1 className='mt-4'><b>KRISHNAGAR PRABHAT SANGHA</b></h1>
                    </div>

                    <h6>KRISHNAGAR | NADIA</h6>
                </center>
            </div>

            <br />
            <br />
          
           
<SocialActivity/>


<Award/>
<br/>
<br/>

        </div>
    )
}

export default Home