import React from 'react'
import Navbar from '../inc/Navbar'
import {Link}  from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
function About() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <ScrollToTop smooth top="100" color="maroon"/>
            <div className="krishnagarjagadhatritraditionalpuja">
                <div className="album py-1">

                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">About Us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />
            <div className='album py-0'>
            <div className='container'>
                <h1 style={{color:"maroon"}} ><b>ABOUT US</b></h1>
                </div>
                </div>
            
            <div className='album py-0'>
                <div className='container'>
                    <div className='row'>
                        <div className="col-md-7 mt-3 me-5">
                           
                                
                                <div class="card-body">
                                    <div className='textcolor'>
                                    <h3 className='mb-2'>KPS GROUP Charitable Trust | SINCE - 1977           </h3>
                                        <p className=" justifalltext card-title mb-4"><b>Krishnagar Prabhat Sangha Charitable Trust</b> has always been a pillar of support for the people of Krishnagar, working tirelessly to serve the community during times of need and preserving local traditions. The trust, led by our dedicated Secretary, <b>Palash Das</b>, and Vice President, <b>Prakash Das</b>, has not only upheld community service but also contributed significantly to maintaining the cultural and spiritual heritage of the area.</p>
            <h3 className='mb-2'>COVID-19 Relief Efforts            </h3>
            <p className=' justifalltext mb-4'>During the challenging period of the COVID-19 pandemic, <b> Krishnagar Prabhat Sangha Charitable Trust</b> took proactive steps to aid the local community. Recognizing the severe impact on families, the trust organized extensive food distribution drives, ensuring that essential food supplies reached vulnerable and affected households. This initiative provided immediate relief to many, reflecting the trust's commitment to community welfare.</p>
            <h3 className='mb-2'>Traditional Jagadhatri Puja            </h3>
            <p className='justifalltext mb-4'>One of the highlights of <b>Krishnagar Prabhat Sangha Charitable Trust's</b> work is its annual <b>Jagadhatri Puja,</b> a festival celebrated with devotion and grandeur. The Jagadhatri Puja not only honors Goddess Jagadhatri but also brings together the entire community to celebrate Krishnagar's rich cultural traditions. The puja is organized with meticulous care, adhering to traditional rituals that have been passed down through generations. It serves as a time for the community to unite, celebrate, and seek blessings for peace and prosperity.</p>

            <h3 className='mb-2'>Message from Leadership            </h3>
            <p className='justifalltext mb-4'>Under the able guidance of <b>Secretary Palash Das</b> and <b>Vice President Prakash Das</b>, <b>Krishnagar Prabhat Sangha Charitable Trust</b> continues its mission of charitable work and cultural preservation. Their leadership has been instrumental in driving these initiatives, fostering a spirit of unity and compassion among all <b>members</b>.</p>
            <h6 className='justifalltext'>The trust looks forward to expanding its support and reaching more people in need while preserving Krishnagar's unique cultural heritage for future generations.</h6>
                                    </div>
                                </div>
                           
                        </div>
                        <div className="col-md-4 mt-3">
                            
                                <iframe src="https://www.facebook.com/plugins/video.php?height=308&href=https%3A%2F%2Fwww.facebook.com%2Fprabhat.sangha.56%2Fvideos%2F272228257530222%2F&show_text=false&width=560&t=0" height="200" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                             
                           
                        </div>
                    </div>
                </div>
            </div>
<br/>
        </div>
    )
}

export default About