import React from 'react'

function SocialActivity() {
    return (
        <div>
            <div className='album py-0'>
                <div className='container'>
                    <center>
                        <h1 style={{color:"darkBlue"}}>  <b style={{color:"maroon"}}>OUR </b>SOCIAL WORK ACTIVITY</h1>
                    </center>
                </div>
            </div>
            <div className='album py-0'>
                <div className='container'>
                    <div className='row'>
                        <div className="col-md-4 mt-3">
                            <div className="card">
                                <iframe src="https://www.facebook.com/plugins/video.php?height=308&href=https%3A%2F%2Fwww.facebook.com%2Fprabhat.sangha.56%2Fvideos%2F272228257530222%2F&show_text=false&width=560&t=0" height="200" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                                <div class="card-body">
                                    <div className='textcolor'>
                                        <h5 className="card-title">#এটাই_একজন_যোগ্য_কর্মীর_আত্মবিশ্বাস ও #মনবল...এই মনের শক্তিতেই এগিয়ে চলো কৃষ্ণনগরবাসী সঙ্গে থাকবে এটা আমাদের বিশ্বাস।...</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="card">
                                <iframe src="https://www.facebook.com/plugins/video.php?height=308&href=https%3A%2F%2Fwww.facebook.com%2Fprabhat.sangha.56%2Fvideos%2F244546766965038%2F&show_text=false&width=560&t=0" height="200" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                                <div class="card-body">
                                    <div className='textcolor'>
                                        <h5 className="card-title">আমাদের সকলের #প্রিয়_পলাশদার উদ্যোগে ও প্রভাত সংঘ ক্লাবের পরিচালনায় আজকের কর্মসূচি.....
                                            ধন্যবাদ জানাই #NADIA24NEWS কে...</h5>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="card">
                                <iframe src="https://www.facebook.com/plugins/video.php?height=308&href=https%3A%2F%2Fwww.facebook.com%2Fprabhat.sangha.56%2Fvideos%2F248091656610549%2F&show_text=false&width=560&t=0" height="200" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>

                                <div class="card-body">
                                    <div className='textcolor'>
                                        <h5 className="card-title">🙏#গরিবের_ভগবান 🙏
                                            এটাই আমাদের সকলের প্রিয় #পলাশ_দা❤❤</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                   

                        <div className="col-md-4 mt-3">
                            <div className="card">
                            <iframe src="https://www.facebook.com/plugins/video.php?height=308&href=https%3A%2F%2Fwww.facebook.com%2FKPSGroupCharitableTrust%2Fvideos%2F2655704811343275%2F&show_text=false&width=560&t=0"  height="200"  scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>

                                <div class="card-body">
                                    <div className='textcolor'>
                                        <h5 className="card-title">#চলার_পথে_চলবো_সাথে …</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="card">
                            <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FKPSGroupCharitableTrust%2Fvideos%2F323806502000997%2F&show_text=true&width=560&t=0" height="200"  scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>

                                <div class="card-body">
                                    <div className='textcolor'>
                                        <h5 className="card-title">Palash da forever
                                        We are proud to have you. your all decision are the best for our club to our socity</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <br />
            <br />
        </div>
    )
}

export default SocialActivity