import './App.css';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';


import Home from "./components/pages/Home";
import Footer from "./components/inc/Footer";
import Contactus from './components/pages/Contactus';
import Member from './components/pages/Member';
import Awardpage from './components/pages/Awardpage';
import Socialpage from './components/pages/Socialpage';
import About from './components/pages/About';

function App() {
  return (
    <Router>
   
    <div>
      
      <Routes>

      <Route axact path="/" element={<Home/>}/>
      <Route axact path="/Contact-us" element={<Contactus/>}/>
      <Route axact path="/Hon'ble-Member" element={<Member/>}/>
      <Route axact path="/Award" element={<Awardpage/>}/>
      <Route axact path="/Social-work" element={<Socialpage/>}/>
      <Route axact path="/about-us" element={<About/>}/>
      </Routes>
    <Footer/>
  
    </div>

    </Router>
  );
}

export default App;
