import React from 'react'
import Awardkps from "../images/Award Trophy.jpg";
import Awardtrophy from "../images/Award.jpg";

function Award() {
    return (
        <div>
                <div className='album py-1'>
                <div className='container'>
                    <center>
                        <h2 style={{color:"darkBlue"}}><b style={{color:"maroon"}}>AWARD </b></h2>
                    </center>
                    </div>

                </div>
            <div className='album py-0'>
                <div className='container'>
                    <div className='row'>
                        <div className="col-md-4 mt-3">
                            <div className="card">
                                <img src={Awardkps} className="card-img-top" alt="Award"/>
                                <div className="card-body">
                                    <div className='textcolor'>
                                    <h3 className="card-title mb-1">Award</h3>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="card">
                                <img src={Awardtrophy} className="card-img-top" alt="Awardtrophy"/>
                                <div className="card-body">
                                <div className='textcolor'>
                                    <h3 className="card-title mb-1"> Award</h3>
                                    </div>
                                 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Award