import React from 'react'
import Navbar from '../inc/Navbar'
import SocialActivity from './SocialActivity'
import {Link} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
function Socialpage() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
       
        <br/>
        <ScrollToTop smooth top="100" color="maroon"/>
        <div className="krishnagarjagadhatritraditionalpuja">
                <div className="album py-1">

                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Social Work Activity</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br/>
        <SocialActivity/>
        
    </div>
  )
}

export default Socialpage